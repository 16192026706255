import { useCallback } from "react";
import { QuestWithId } from "../../models/api/quest";
import useUpdateQuestMetaDataAndNodeData from "./useUpdateQuestMetaDataAndNodeData";

export default function useToggleQuestPlayerVisibility(questId: string, quest?: QuestWithId) {
  const { isUpdating: isToggling, updateQuestMetaDataAndNodeData } = useUpdateQuestMetaDataAndNodeData();

  const toggleQuestPlayerVisibility = useCallback(() => {
    if (quest == null) {
      return;
    }

    const { data } = quest;
    const nodes = data?.nodes ?? [];
    const edges = data?.edges ?? [];

    updateQuestMetaDataAndNodeData({
      questId,
      partialQuest: { visible: !quest.visible },
      questData: { nodes, edges },
    });
  }, [questId, quest]);

  return {
    isToggling,
    toggleQuestPlayerVisibility,
  };
}
