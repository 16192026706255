import { CardHeader, Flex, FormControl, FormLabel, Heading, HStack, Input, Stack, Text } from "@chakra-ui/react";
import { ChakraProps } from "@chakra-ui/system";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { Form } from "react-router-dom";
import QuestTogglePlayerVisibilityButton from "../../components/quests/QuestTogglePlayerVisibilityButton";
import useUpdateQuestMetaData from "../../hooks/quests/useUpdateQuestMetaData";
import { QuestWithId } from "../../models/api/quest";
import Tooltip from "../../ui/base/chakra/Tooltip";
import FormTextArea from "../../ui/form/FormTextArea";
import BaseNodeCopyNodeIdButton from "../reactflow/nodes/base/BaseNodeCopyNodeIdButton";
import QuestNavigateToButton from "./QuestNavigateToButton";
import QuestRefreshButton from "./QuestRefreshButton";
import QuestStartConditions from "./QuestStartConditions";
import QuestStatusButton from "./QuestStatusButton";

interface QuestNodeHeaderProps extends ChakraProps {
  quest: QuestWithId;
  isLoading: boolean;
}

function QuestNodeHeader({ color, quest, isLoading }: QuestNodeHeaderProps) {
  const { isUpdating, updateQuestMetaData } = useUpdateQuestMetaData();

  const { register, handleSubmit } = useForm<Pick<QuestWithId, "name" | "description">>({
    defaultValues: useMemo(
      () => ({
        name: quest.name,
        description: quest.description,
      }),
      [quest]
    ),
    mode: "all",
  });

  const { questId, isReady = false } = quest;

  function handleUpdate({ name, description }: Pick<QuestWithId, "name" | "description">) {
    if (name === quest.name && description === quest.description) {
      return;
    }

    updateQuestMetaData({
      questId,
      partialQuest: {
        name,
        description,
      },
    });
  }

  const headerButtons = useMemo(
    () => (
      <>
        <QuestRefreshButton color={color} questId={questId} isLoading={isLoading} isDisabled={isLoading} />
        <QuestNavigateToButton color={color} questId={questId} />
      </>
    ),
    [color, questId, isLoading]
  );

  return (
    <>
      <CardHeader borderBottomWidth={1} borderTopWidth={8} borderTopColor={color} mx={-3} mt={-3}>

        <Flex alignItems={"center"} justifyContent={"space-between"} flexGrow={1} gap={8}>
          <Tooltip color={color} label={quest.description}>
            <Text color={color} casing={"uppercase"} fontWeight={700}>
              {quest.name}
            </Text>
          </Tooltip>

          <HStack className={"nodrag"}>
            <BaseNodeCopyNodeIdButton color={color} />
            
            <QuestTogglePlayerVisibilityButton questId={questId} />

            {headerButtons}
          </HStack>
        </Flex>
      </CardHeader>

      <QuestStatusButton
        questId={questId}
        nodes={quest.data?.nodes}
        edges={quest.data?.edges}
        size={"sm"}
        variant={"outline"}
        borderRadius={0}
      />

      <Form onSubmit={handleSubmit(handleUpdate)} onBlur={handleSubmit(handleUpdate)}>
        <Stack className={"nodrag"}>
          <FormControl>
            <FormLabel>
              <Heading size={"sm"}>
                <Text color={color} casing={"uppercase"}>
                  Quest Name
                </Text>
              </Heading>
            </FormLabel>

            <Input
              borderColor={color}
              borderWidth={2}
              borderRadius={0}
              color={color}
              {...register("name")}
              disabled={isLoading || isUpdating}
            />
          </FormControl>

          <FormTextArea color={color} {...register("description")} disabled={isLoading || isUpdating}>
            <Heading size={"sm"}>
              <Text color={color} casing={"uppercase"}>
                Quest Description
              </Text>
            </Heading>
          </FormTextArea>
        </Stack>
      </Form>

      <QuestStartConditions color={color} quest={quest} />
    </>
  );
}

export default QuestNodeHeader;
