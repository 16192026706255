import { Icon, IconButton, Tooltip } from "@chakra-ui/react";
import { memo } from "react";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import useQuest from "../../hooks/quests/useQuest";
import useToggleQuestPlayerVisibility from "../../hooks/quests/useToggleQuestPlayerVisibility";

interface QuestResetButtonProps {
  questId: string;
}

function QuestTogglePlayerVisibilityButton({ questId }: QuestResetButtonProps) {
  const { isLoading, quest } = useQuest(questId);
  const { isToggling, toggleQuestPlayerVisibility } = useToggleQuestPlayerVisibility(questId, quest);

  const visible = quest?.visible;

  return (
    <Tooltip label={"toggle player visibility"}>
      <IconButton
        aria-label={"toggle player visibility"}
        icon={<Icon as={visible ? IoMdEye : IoMdEyeOff} position={"absolute"} />}
        color={"white"}
        isLoading={isLoading}
        isDisabled={isToggling}
        onClick={toggleQuestPlayerVisibility}
      />
    </Tooltip>
  );
}

export default memo(QuestTogglePlayerVisibilityButton);
